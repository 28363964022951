import React from 'react';
import { Box, Typography, Grid, Container } from '@mui/material';
import { styled } from '@mui/material/styles';
import Link from '@mui/material/Link';
import InstagramIcon from '@mui/icons-material/Instagram';

import header from '../Img/Our_team/our_team_header.png';
import established from '../Img/Our_team/est2011.png';
import background from '../Img/Our_team/bg.png';

const Header = styled('img')(({ theme }) => ({
  height: '80%',
  maxWidth: '100%',
  paddingBottom:"2%",
  display: 'none',
  [theme.breakpoints.down('md')]: {
    height: 'auto',
    maxWidth: '70%',
  },
}));

const Established = styled('img')(({ theme }) => ({
  height: 'auto',
  paddingTop: '2%',
  maxHeight: "20%",
  maxWidth: '100%',
  [theme.breakpoints.down('md')]: {
    height: 'auto',
    maxWidth: '50%',
  },
}));

const ProductContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  alignItems: 'center',
  justifyContent: 'flex-start', // Changed to flex-start to align items at the top
  [theme.breakpoints.down('md')]: {
    height: 'auto',
  },
}));

const MainImageContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: 'transparent',
  alignItems: 'center',
  //marginTop: '2rem', // Add margin to separate the image from the text
}));

const MainImage = styled('img')(({ theme }) => ({
  width: '100%', // Scale down the image to 100% of its container width
  height: 'auto', // Maintain the aspect ratio
  objectFit: 'cover', // Ensure the image scales correctly within its container
  [theme.breakpoints.down('md')]: {
    width: '100%',
    height: 'auto',
  },
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: '#000',
  fontSize: '1.5rem',
  lineHeight: '1.5',
  //fontWeight: 600,
  fontFamily: "industry-inc-base",

  maxWidth: '60%',
  textAlign: 'center', // Center the text
  margin: '2rem 0', // Add margin to create space around the text
  [theme.breakpoints.down('md')]: {
    fontSize: '1rem',
    maxWidth: '80%',
  },
}));

const OurTeam = () => {
  return (
    <Container disableGutters maxWidth="100vw" sx={(theme) => ({
      margin: 0,
      padding: 0,
      width: '100%',
      height: 'auto',
      paddingBottom: "5%",
      overflow: 'hidden',
      [theme.breakpoints.down('md')]: {
        height: 'auto',
        paddingBottom: "5%",
      },
      [theme.breakpoints.down('lg')]: {
        height: 'auto',
        paddingBottom: "5%",
      },
    })}>
      <ProductContainer id="our-story">
        <Grid container direction="column" alignItems="center" justifyContent="center" sx={{ width: '100%', height: "30%" }}>
          <Header src={header} alt="Our Products Header" />
          <Established src={established} alt="Established 2011" />
        </Grid>
        <MainImageContainer>
          <MainImage src={background} />
        </MainImageContainer>
        <Grid container direction="column" alignItems="center" justifyContent="center" sx={{ padding: '2rem 0', width: '100%' }}>
          <StyledTypography>
            Operating in tandem with the internationally renowned Dutch Kills, Hundredweight has been fabricating and delivering crystal-clear ice to bars and restaurants throughout New York City and the Tri-State area since 2011. We are a family-owned and operated business, and the exemplary quality of our ice is a genuine testament to our legendary Milk & Honey cocktail pedigree.
            <Link href="https://www.instagram.com/hundredweightice/" target="_blank" rel="noopener noreferrer" style={{ color: '#000', textDecoration: 'none', display: 'flex', alignItems: 'center',  textAlign: 'center', alignContent: 'center', justifyContent: 'center' }}>
              <InstagramIcon style={{ marginRight: 8, color: '#000' }} /> @HUNDREDWEIGHTICE
            </Link>
          </StyledTypography>
        </Grid>
      </ProductContainer>
    </Container>
  );
};

export default OurTeam;
