import React from 'react';
import { Box, Grid, Card, CardMedia, Container, IconButton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';

import header from '../Img/Press/Press_header.png';
import image1 from '../Img/Press/1.jpg';
import image2 from '../Img/Press/2.jpg';
import image3 from '../Img/Press/3.jpg';
import image4 from '../Img/Press/4.jpg';
import image5 from '../Img/Press/5.jpg';
import image6 from '../Img/Press/6.jpg';
import image7 from '../Img/Press/7.jpg';
import image8 from '../Img/Press/8.jpg';
import image9 from '../Img/Press/9.jpg';
import image10 from '../Img/Press/10.jpg';
import image11 from '../Img/Press/11.jpg';

const pressItems = [
  {
    title: 'HUNDREDWEIGHT ICE WHERE MICHELIN STARRED RESTAURANTS GET INGREDIENTS',
    image: image11,
    url: "https://vimeo.com/1012395196?share=copy#t=0",
    order: 1,
  },
  {
    title: 'HOW RICHARD BOCCATO BECAME ONE OF THE BIGGEST COCKTAIL ICE DEALERS IN NYC',
    image: image5,
    url: 'https://www.youtube.com/watch?v=ET8mqVGDQ1s&t=3s',
    order: 2,
  },
  {
    title: 'CBS SUNDAY MORNING COOL: THE STORY OF ICE',
    image: image4,
    url: 'https://www.youtube.com/watch?v=_I6KDbp-EQA&t=1s',
    order: 3,
  },
  {
    title: 'CBS NEWS HUNDREWEIGHT ICE STORY',
    image: image10,
    url: 'https://vimeo.com/989219127',
    order: 4,
  },
  {
    title: 'THE IMPORTANCE OF HUNDREDWEIGHT ICE IN CRAFT COCKTAILS',
    image: image3,
    url: 'https://www.youtube.com/playlist?list=PLEGJxlcPVUgCassZ7sXkQxyt4rLppA9UG',
    order: 5,
  },
  {
    title: 'THE ICE MAN COMETH - PUNCH',
    image: image2,
    url: 'https://vimeo.com/77942855',
    order: 6,
  },
  {
    title: 'PATY BOCCATO, THE ICE QUEEN NY',
    image: image8,
    url: 'https://youtu.be/YDjFxz4URyg',
    order: 7,
  },
  {
    title: 'RICHARD BOCCATO 2012 MILAN INTERVIEW',
    image: image1,
    url: 'https://vimeo.com/226226201',
    order: 8,
  },
  {
    title: 'CREATE NEW REVENUE STREAMS: BECOME YOUR OWN SUPPLIER, AND THEN SUPPLY OTHERS',
    image: image6,
    url: 'https://www.forbes.com/sites/coelicarr/2011/09/06/create-new-revenue-streams-become-your-own-supplier-and-then-supply-others/#770535cd62e2',
    order: 9,
  },
  {
    title: 'BAR ICE WITH A PEDIGREE',
    image: image7,
    url: 'https://archive.nytimes.com/dinersjournal.blogs.nytimes.com/2011/04/13/bar-ice-with-a-pedigree/?_r=0',
    order: 10,
  },
  {
    title: 'EDIBLES ICE QUEENS',
    image: image9,
    url: 'https://ediblequeens.ediblecommunities.com/drink/ice-mages-queens-inside-obsessive-world-artisanal-ice-cubes',
    order: 11,
  },
  
];

const Header = styled('img')(({ theme }) => ({
  height: 'auto',
  maxHeight: "50%",
  maxWidth: '100%',
  [theme.breakpoints.down('md')]: {
    height: 'auto',
    maxWidth: '80%',
  },
}));

const PressContainer = styled(Box)(({ theme }) => ({
  minHeight: '100vh',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  display: 'flex',
  paddingBottom: theme.spacing(4),
  [theme.breakpoints.down('md')]: {
    minHeight: '100vh',
  },
}));

const PlayButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  color: 'white',
  fontSize: '3rem',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
  },
}));

const Press = () => {
  return (
    <Container disableGutters maxWidth="100vw" sx={{ margin: 0, padding: 0, width: '100%' }}>
      <PressContainer id="press">
        <Grid container direction="column" alignItems="center" justifyContent="center" sx={{ width: '100%' }}>
          <Header src={header} alt="Press Header" />
        </Grid>
        <Grid container alignItems="center" justifyContent="center" spacing={0} sx={{ width: '90%', padding: "2%" }}>
          {pressItems.map((item, index) => (
            <Grid item key={index} xs={12} sm={6} md={4} sx={{ padding: 1, position: 'relative' }}>
              <a href={item.url} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                <Card sx={{ height: '100%', position: 'relative' }}>
                  <CardMedia
                    component="img"
                    image={item.image}
                    alt={item.title}
                    sx={{ height: "100%", objectFit: 'cover' }}
                  />
                </Card>
              </a>
            </Grid>
          ))}
        </Grid>
      </PressContainer>
    </Container>
  );
};

export default Press;
