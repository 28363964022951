import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography, Grid, Container } from '@mui/material';
import { styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import background from '../../Img/Our_Products/05.Highball/highball_moneyshot.jpg';
import productsBackground from '../../Img/Our_Products/Ice.png';
import header from '../../Img/Our_Products/our_products_header.png';
import logo from '../../Img/Our_Products/mountain_corner.png';
import title from '../../Img/Our_Products/05.Highball/HighballSpears_.png';
import image1 from '../../Img/Our_Products/05.Highball/1.jpg';
import image2 from '../../Img/Our_Products/05.Highball/2.jpg';
import image3 from '../../Img/Our_Products/05.Highball/3.jpg';
import image4 from '../../Img/Our_Products/05.Highball/4.jpg';


const ProductContainer = styled(Box)(({ theme }) => ({
  height: "80vh",
  alignItems: 'center', // vertically centers the child content (text)
  justifyContent: 'center', // horizontally centers the child content (text)
  [theme.breakpoints.down('md')]: {
    height: '50vh',
  },
}));



const Logo = styled('img')(({ theme }) => ({
  width: "10%",  // Logo size, adjust as needed
  position: 'absolute',
  bottom: "5%",
  left: "5%",
  [theme.breakpoints.down('md')]: {
    display: 'none'
  },
}));


const ThumbnailContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  right: "0%",
  width: "100%",  // Logo size, adjust as needed
  bottom: 0,
  display: 'flex',
  justifyContent: 'center',
  padding: '10px',
  gap: '10px',
  margin: '10px',  // Add some margin to ensure it does not stick to the edges
  [theme.breakpoints.down('md')]: {
    display: 'none'
  },
}));


const Thumbnail = styled('img')({
  width: "20%",  // Logo size, adjust as needed
  aspectRatio: 2/1,
  margin: '0 5px',
  cursor: 'pointer',
  opacity: 0.9,
  '&:hover': {
    opacity: 1,
  }
});

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: '#fff',
  fontSize: '1.25rem',
  lineHeight: '1',
  //fontWeight: 600,
  textTransform: 'uppercase',
  maxWidth: '95%',
  fontFamily: 'Open Sans Regular',
  fontFamily: "industry-inc-base",

  textAlign: 'center',
  [theme.breakpoints.down('md')]: {
    color: '#000',
    fontSize: '.6rem',
    maxWidth: '95%',
    padding: '3%',
    paddingBottom: '5%',
    textAlign: 'center',
    position: 'absolute',
    bottom: 0,
    left: 0,
  },
}));


const TitleText = styled('img')(({ theme }) => ({
  width: '90%',
  transition: 'width 0.2s ease-in-out',
  [theme.breakpoints.down('md')]: {
    width: '90%',
    paddingBottom: "5%",
    position: 'absolute',
    top: '8%',
    left: '5%',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const MainImage = styled(Box)(({ image, theme }) => ({
  backgroundImage: `url(${image})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  [theme.breakpoints.down('md')]: {
    height: '100%',
    backgroundPosition: 'left',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat'
  },
}));

const HighBallSpears = () => {
    const [mainImage, setMainImage] = useState(background);
  const [currentIndex, setCurrentIndex] = useState(0);
  const images = [image1, image2, image3, image4];
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const containerRef = useRef(null);

  const handleTap = () => {
    if (isMobile) {
      const images = [background, image1, image2, image3, image4];
      const nextIndex = (currentIndex + 1) % images.length;
      setCurrentIndex(nextIndex);
      setMainImage(images[nextIndex]);
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (!entry.isIntersecting) {
          setMainImage(background);
          setCurrentIndex(0);
        }
      },
      { threshold: 0.1 }
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, [containerRef]);  return (
    <Container disableGutters maxWidth="100vw" sx={{ margin: 0, padding: 0, width: "100%", height: "100%" }}>
      <ProductContainer id="two-inch-cubes" ref={containerRef}>
        <MainImage image={mainImage} onClick={handleTap}>
          <Grid container sx={{ height: '100%', width: '100%' }}>
            <Grid item xs={0} lg={6}>
              <Logo src={logo} alt="Company Logo" />
            </Grid>
            <Grid item xs={12} lg={6} container direction="column" sx={{ height: '100%' }}>
            <Grid item xs={1} lg={1} xl={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <></>
              </Grid>
              <Grid item xs={4} lg={1} xl={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                {(mainImage === background || isMobile) && (
                  <TitleText src={title} alt="Product Title" />
                )}
              </Grid>
              <Grid item xs={7} lg={10} xl={9} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', position: 'relative' }}>
              {mainImage === background && (
                <StyledTypography variant="body1">
                    The perfect piece to fit and complement any long drink. Our spears are specifically designed to accommodate cokctails being served in any variety of highball or collins glass. Like our cubes, these elongated spears will keep your drinks colder while at the same time yielding the ideal amount of water content for the duration of their life in the glass.
                    </StyledTypography>
              )}
                <Grid item xs={5} lg={6} container direction="column" justifyContent="space-between">
                  <ThumbnailContainer>
                    {images.map((img, index) => (
                      <Thumbnail
                        key={index}
                        src={img}
                        alt={`Thumbnail ${index + 1}`}
                        onMouseOver={() => setMainImage(img)}
                        onTouchStart={() => setMainImage(img)}
                        onTouchEnd={() => setMainImage(background)}
                        onMouseLeave={() => setMainImage(background)}
                      />
                    ))}
                  </ThumbnailContainer>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </MainImage>
      </ProductContainer>
    </Container>
  );
};

export default HighBallSpears;
